<template>
  <tr>
    <td>
      <span v-if="portal">
        {{ portal.name }}
      </span>
    </td>
    <td>{{ publication.start|datetime }}</td>
    <td>
      <span v-if="startCollaborator">
        {{ startCollaborator.first_name }} {{ startCollaborator.last_name }}
      </span>
      <span v-if="publication.project && publication.property && project">
        via project {{ project.reference }}
      </span>
    </td>
    <td>{{ publication.stop|datetime }}</td>
    <td>
      <span v-if="stopCollaborator">
        {{ stopCollaborator.first_name }} {{ stopCollaborator.last_name }}
      </span>
    </td>
    <td>
      {{ publication.id }} - {{ publication.status_display }}
      <span v-if="user.is_staff && (publication.status == 1 || publication.status == 4 || publication.status == 2)">
        <button
          v-if="!isSending"
          class="btn btn-xs btn-default"
          @click="sendPublication"
        >
          Doorsturen
        </button>
        <Loader v-else :inline="true" message="doorsturen..." />
      </span>
    </td>
  </tr>
</template>

<script>
/**
   * The PortalPublicationDetails component is a table record used by the PortalPublications component. It is rendered
   * for each PortalPublication in the history modal and shows additional details for that PortalPublication.
   * When viewed by a staff member a PortalPublication can be triggered for re-sending to the Portal.
   */
import Loader from '@/components/iam/Loader'

import { mapGetters, mapState } from 'vuex'
import { errorModal } from '@/modalMessages'

import { updatePropertyPortalPublication } from '@/services/properties'
import { updateProjectPortalPublication } from '@/services/projects'

export default {
  name: 'PortalPublicationDetails',
  components: { Loader },
  props: {
    publication: {
      type: Object,
      required: true
    },
    portals: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isSending: false,
      users: {}
    }
  },
  computed: {
    ...mapGetters('properties', ['getProjectById']),
    ...mapGetters('account', ['collaborator']),
    ...mapState('account', ['user']),

    project () {
      return this.getProjectById(this.publication.project)
    },
    portal () {
      return this.portals.find(portal => portal.id === this.publication.portal)
    },
    startCollaborator () {
      return this.publication.started_by_user
    },
    stopCollaborator () {
      return this.publication.stopped_by_user
    }
  },
  methods: {
    async sendPublication () {
      /* Cause an update of the PortalPublication that triggers a resend to the Portal.
       * This method saves the publiation options without any changes to trigger a status change to UPDATED_NEEDED. This
       * new status will cause the Portal Exporter to resend the publication to the Portal. */
      try {
        this.isSending = true
        const payload = {}
        if (this.publication.property) await updatePropertyPortalPublication(this.publication.property, this.publication.id, payload)
        if (this.publication.project) await updateProjectPortalPublication(this.publication.project, this.publication.id, payload)
        this.isSending = false
        return this.$emit('update')
      } catch (e) {
        console.error(e)
        errorModal('Er is iets misgegaan bij het updaten van de portaalpublicatie')
      }
    }
  }
}
</script>
